import { parseSearchState, SearchProvider } from '@faststore/sdk'
import { useMemo } from 'react'
import ProductGalleryV2 from 'src/components/sections/ProductGalleryV2'
import { ITEMS_PER_PAGE } from 'src/constants'
import Breadcrumb from 'src/components/ui/Breadcrumb/Breadcrumb'
import { applySearchState } from 'src/sdk/search/state'
import type {
  CollectionPageQueryQuery,
  ServerCollectionPageQueryQuery,
  CollectionPageQueryQueryVariables,
} from '@generated/graphql'
import type { PageProps } from 'gatsby'
import type { SearchState } from '@faststore/sdk'

export type Props = PageProps<
  CollectionPageQueryQuery,
  CollectionPageQueryQueryVariables,
  unknown,
  ServerCollectionPageQueryQuery
> & { slug: string; serverData: any }

const useSearchParams = (props: Props): SearchState => {
  const {
    location: { href, pathname },
  } = props

  return useMemo(() => {
    const maybeState = href ? parseSearchState(new URL(href)) : null

    return {
      page: maybeState?.page ?? 0,
      base: maybeState?.base ?? pathname,
      selectedFacets:
        maybeState && maybeState.selectedFacets.length > 0
          ? maybeState.selectedFacets
          : [],
      term: maybeState?.term ?? null,
      sort: maybeState?.sort ?? 'score_desc',
    }
  }, [href, pathname])
}

export function CollectionPageTemplate(props: Props) {
  const { serverData } = props

  const originalSearch = serverData?.data?.originalURL
    .replace('/colecao?', '')
    .replace('/colecao/?', '')
    .replace('/collection?', '')
    .replace('/collection/?', '') as string

  const newSearch = [
    ...new Set([
      ...props.location?.search?.replace('?', '')?.split('&'),
      ...originalSearch?.split('&'),
    ]),
  ].join('&')

  const searchParams = useSearchParams({
    ...props,
    location: {
      ...props.location,
      href: `http://url.com${serverData?.data?.newURL}?${newSearch}`,
    },
  })

  const slug = serverData?.data?.newURL?.split('/').filter(Boolean)
  const titleBySlug = slug ? slug[slug?.length - 1] : ''

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      <div className="layout__content mb-6">
        <Breadcrumb
          breadcrumbList={[
            {
              item: props.slug ?? '',
              name: '',
              position: 0,
            },
          ]}
        />
      </div>

      <ProductGalleryV2
        title={titleBySlug ?? searchParams?.term ?? ''}
        searchEngine="vtex"
        type="collection"
        typeValidation="byTermOrCollection"
        dataSports={null}
      />
    </SearchProvider>
  )
}
