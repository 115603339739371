import { gql } from '@vtex/graphql-utils'
import { graphql } from 'gatsby'
import { mark } from 'src/sdk/tests/mark'
import type { PageProps } from 'gatsby'
import { slugify } from 'src/utils/slugfy'
import { getCMSPageDataByContentType } from 'src/cms/client'
import { CollectionPageTemplate } from 'src/components/collection/CollectionPageTemplate'
import { CategoryPageTemplate } from 'src/components/category/CategoryPageTemplate'
import axios from 'axios'
import type {
  CollectionPageQueryQuery,
  CollectionPageQueryQueryVariables,
  ServerCollectionPageQueryQuery,
} from '@generated/graphql'
import Seo from 'src/components/seo/Seo'

export type Props = PageProps<
  CollectionPageQueryQuery,
  CollectionPageQueryQueryVariables,
  unknown,
  ServerCollectionPageQueryQuery
> & { slug: string; serverData: any }

function Page(props: Props) {
  const {
    serverData,
    data: { site },
    location: { host },
    slug,
  } = props

  const seo = serverData?.seo

  const canonical = host !== undefined ? `https://${host}/${slug}` : `/${slug}`

  const title = seo?.title ?? site?.siteMetadata?.description
  const description = seo?.metaTagDescription ?? site?.siteMetadata?.description

  return (
    <>
      {/* SEO */}
      <Seo
        title={title}
        description={description}
        canonical={canonical}
        language="pt-br"
        openGraph={{
          type: 'website',
          title,
          description,
        }}
      />

      {(serverData as any)?.pageType === 'collection' ? (
        <CollectionPageTemplate {...(props as any)} />
      ) : (
        <CategoryPageTemplate {...(props as any)} title={title} />
      )}
    </>
  )
}

export const querySSG = graphql`
  query CollectionPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }
  }
`

/**
 * This query is run during SSG
 * */
export const querySSR = gql`
  query ServerCollectionPageQuery($slug: String!) {
    collection(slug: $slug) {
      seo {
        title
        description
      }
      breadcrumbList {
        itemListElement {
          item
          name
          position
        }
      }
      meta {
        selectedFacets {
          key
          value
        }
      }
    }
  }
`

export const getServerData = async ({
  params: { slug },
}: {
  params: Record<string, string>
}) => {
  const ONE_YEAR_CACHE = `max-age=3600, s-maxage=3600, stale-while-revalidate`

  try {
    const { execute } = await import('src/server/index')
    const newSlug = slugify(slug)

    const collectionPage = (await getCMSPageDataByContentType(
      'collection-page',
      {
        filters: { name: 'Coleções - URLs Amigavéis (cuidado ao editar)' },
      }
    )) as {
      sections: Array<{
        name: string
        data: {
          collections: Array<{
            newURL: string
            originalURL: string
            metatag: { title: string; description: string }
          }>
        }
      }>
    } | null

    const urls = collectionPage?.sections.find(
      (section) => section.name === 'Páginas de coleção com URL amigável'
    )

    const friendlyURLs = urls?.data.collections

    const { data } = await execute({
      operationName: querySSR,
      variables: { slug: newSlug },
    })

    const { data: seo } = await axios.get(
      `https://decathlonstore.myvtex.com/api/catalog_system/pub/portal/pagetype/${newSlug}`
    )

    const collectionPageFromCms = friendlyURLs?.find(
      (url) => url.newURL === `/${slug}`
    )

    const physicalProduct = newSlug.split('/')

    if (physicalProduct.length === 3 && physicalProduct[0] === 'product') {
      const url = `/pesquisa?q=${physicalProduct[1]}&utm_source=loja-fisica&utm_medium=qr-code-balisagem&utm_campaign=loja-${physicalProduct[2]} `

      return {
        status: 301,
        props: {},
        headers: {
          'cache-control': ONE_YEAR_CACHE,
          location: url,
        },
      }
    }

    const cmsDataBrands = await getCMSPageDataByContentType('marcas', {
      filters: { name: `${newSlug}` },
    })

    const cmsDataSports = await getCMSPageDataByContentType('esportes', {
      filters: { name: `${newSlug}` },
    })

    if (cmsDataBrands !== null) {
      const url = `/marcas/${newSlug}`

      return {
        status: 301,
        props: null,
        headers: {
          'cache-control': ONE_YEAR_CACHE,
          location: url,
        },
      }
    }

    if (cmsDataSports !== null) {
      const url = `/esportes/${newSlug}`

      return {
        status: 301,
        props: null,
        headers: {
          'cache-control': ONE_YEAR_CACHE,
          location: url,
        },
      }
    }

    if (data === null && !collectionPageFromCms) {
      return {
        status: 301,
        props: null,
        headers: {
          'cache-control': ONE_YEAR_CACHE,
          location: `/pesquisa/?q=${encodeURIComponent(
            newSlug.replaceAll('/', ' ')
          )}`,
        },
      }
    }

    if (slug !== newSlug) {
      return {
        status: 301,
        props: null,
        headers: {
          'cache-control': ONE_YEAR_CACHE,
          location: `/${newSlug}`,
        },
      }
    }

    if (collectionPageFromCms) {
      return {
        status: 200,
        props: {
          pageType: 'collection',
          data: collectionPageFromCms,
          seo: {
            title: collectionPageFromCms.metatag.title,
            description: collectionPageFromCms.metatag.description,
          },
        },
        headers: {
          'cache-control': ONE_YEAR_CACHE,
        },
      }
    }

    return {
      status: 200,
      props: {
        pageType: 'category',
        data,
        seo,
      },
      headers: {
        'cache-control': ONE_YEAR_CACHE,
      },
    }
  } catch (err) {
    console.error(err)

    return {
      status: 500,
      props: {},
      headers: {
        'cache-control': 's-maxage=31536000, stale-while-revalidate',
      },
    }
  }
}

Page.displayName = 'Page'
export default mark(Page)
