import { parseSearchState, SearchProvider } from '@faststore/sdk'
import { useEffect, useMemo } from 'react'
import ProductGalleryV2 from 'src/components/sections/ProductGalleryV2'
import { ITEMS_PER_PAGE } from 'src/constants'
import { applySearchState } from 'src/sdk/search/state'
import {
  useCategoryViewEvent,
  useDepartmentViewEvent,
} from 'src/utils/gtmEvents'
import type {
  CollectionPageQueryQuery,
  ServerCollectionPageQueryQuery,
  CollectionPageQueryQueryVariables,
} from '@generated/graphql'
import type { PageProps } from 'gatsby'
import type { SearchState } from '@faststore/sdk'
import { useLinxCategoryView } from 'src/sdk/linx/hooks/useLinxCategoryView'
import Breadcrumb from 'src/components/ui/Breadcrumb/Breadcrumb'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'
import { B2B } from 'src/store-config/B2B-Config'
import { B2C } from 'src/store-config/B2C-Config'

export type Props = PageProps<
  CollectionPageQueryQuery,
  CollectionPageQueryQueryVariables,
  unknown,
  ServerCollectionPageQueryQuery
> & { slug: string; serverData: any; title: string }

const useSearchParams = (props: Props): SearchState => {
  const {
    location: { href, pathname },
  } = props

  return useMemo(() => {
    const maybeState = href ? parseSearchState(new URL(href)) : null

    return {
      page: maybeState?.page ?? 0,
      base: maybeState?.base ?? pathname,
      selectedFacets:
        maybeState && maybeState.selectedFacets.length > 0
          ? maybeState.selectedFacets
          : [],
      term: maybeState?.term ?? null,
      sort: maybeState?.sort ?? 'score_desc',
    }
  }, [href, pathname])
}

export function CategoryPageTemplate(props: Props) {
  const { serverData, title } = props

  const isB2B = checkEnviromentIsB2B()

  const searchParams = useSearchParams(props)

  const categories =
    serverData.data?.collection?.breadcrumbList?.itemListElement?.map(
      (item: any) => item.name
    )

  const { sendCategoryViewEvent } = useLinxCategoryView({
    categories,
  })

  useEffect(() => {
    sendCategoryViewEvent()
  }, [sendCategoryViewEvent])

  useDepartmentViewEvent()
  useCategoryViewEvent()

  if (serverData === null) {
    return null
  }

  const {
    data: { collection },
  } = serverData

  const titlePage =
    collection?.breadcrumbList.itemListElement[
      collection?.breadcrumbList.itemListElement.length - 1
    ]

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      <div className="layout__content mb-6">
        <Breadcrumb
          breadcrumbList={collection?.breadcrumbList.itemListElement}
        />
      </div>

      <ProductGalleryV2
        title={titlePage?.name ?? title}
        department={
          collection?.breadcrumbList?.itemListElement[
            collection?.breadcrumbList?.itemListElement?.length - 1
          ]
        }
        searchEngine={isB2B ? 'vtex' : 'linx'}
        salesChannel={isB2B ? B2B.salesChannel : B2C.salesChannel}
        type="seller"
        typeValidation="byDepartment"
        dataSports={null}
      />
    </SearchProvider>
  )
}
